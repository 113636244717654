import { Box, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { getSpecificBranchesInsights } from "../api/getSpecificBranchesInsights";
import { AllBranchesComparisonTable } from "./AllBranchesComparisonTable";
import { primaryColors } from "../../../helpers/customColors";
import { AllBranchesQuestionBarChart } from "./AllBranchesQuestionBarChart";
import { SpecificBranchesQuestionsInsights } from "./SpecificBranchesQuestionsInsights";
import { fontWeightVariations } from "../../../helpers/customFont";
import { SpecificBranchesRawNumbersCards } from "./SpecificBranchesRawNumbersCards";
import { AuthenticationContext } from "../../../features/login/contexts/AuthenticationContext";
import {
  markSelectedBranches,
  markSelectedDivisions,
} from "../../tabbed-landing-page/hooks/useInsightsFiltersState";

export const RevampedLandingPage = ({
  selectedFiltersState,
  branchesAndDivisionsData,
}) => {
  const [
    specificBranchesNumbersCardsAPIData,
    setSpecificBranchesNumbersCardsAPIData,
  ] = useState();
  const [
    specificBranchesQuestionsInsightsAPIData,
    setSpecificBranchesQuestionsInsightsAPIData,
  ] = useState();

  const [isFetchingSpecificBranchesData, setIsFetchingSpecificBranchesData] =
    useState(true);

  function fetchAndHandleSpecificBranchesInsights({
    startDate,
    endDate,
    governorates,
    facilityTypes,
    branches,
    divisions,
  }) {
    setIsFetchingSpecificBranchesData(true);

    getSpecificBranchesInsights({
      startDate,
      endDate,
      governorates,
      facilityTypes,
      branches,
      divisions,
    }).then(function logData(response) {
      setSpecificBranchesQuestionsInsightsAPIData(
        response.data.questions_cards
      );
      setSpecificBranchesNumbersCardsAPIData(response.data.numbers_cards);

      setIsFetchingSpecificBranchesData(false);
    });
  }

  const callInsightsAPIs = useCallback(
    ({ startDate, endDate, governorates, branches, facilityTypes, divisions }) => {
      fetchAndHandleSpecificBranchesInsights({
        startDate,
        endDate,
        governorates: governorates,
        facilityTypes: facilityTypes,
        branches,
        divisions,
      });
    },
    []
  );

  useEffect(
    function handleChangeInSelectedFiltersState() {
      fetchAndHandleSpecificBranchesInsights({
        governorates: selectedFiltersState.governorates,
        facilityTypes: selectedFiltersState.facilityTypes,
        branchesIDs: selectedFiltersState?.branches.join(","),
        startDate: selectedFiltersState.startDate,
        endDate: selectedFiltersState.endDate,
      });
    },
    [selectedFiltersState]
  );
  useEffect(
    function callInsightsAPIsOnMount() {
      callInsightsAPIs({ ...selectedFiltersState });
    },
    [callInsightsAPIs, selectedFiltersState]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" gap={"20px"}>
        {/* First Section */}
        <SpecificBranchesRawNumbersCards
          rawNumbersCardsData={specificBranchesNumbersCardsAPIData}
          selectedStartDate={selectedFiltersState.startDate}
          selectedEndDate={selectedFiltersState.endDate}
          isFetching={isFetchingSpecificBranchesData}
        />
        {/* Second Section */}
        <SpecificBranchesQuestionsInsights
          rawQuestionsInsightsData={specificBranchesQuestionsInsightsAPIData}
          branchesData={markSelectedBranches({
            branchesData: branchesAndDivisionsData?.branches,
            selectedBranchesIDs: selectedFiltersState.branches,
          })}
          divisionsData={markSelectedDivisions({
            divisionsData: branchesAndDivisionsData?.divisions,
            selectedDivisionsIDs: selectedFiltersState.divisions,
          })}
          isFetching={isFetchingSpecificBranchesData}
          selectedStartDate={selectedFiltersState.startDate}
          selectedEndDate={selectedFiltersState.endDate}
          insightsInfo={insightsInfo}
        />
        {/* {selectedFiltersState?.branches?.length < 2 &&
        selectedFiltersState?.divisions?.length < 2 ? (
          <NotEnoughBranchesSelected />
        ) : (
          <> */}
        {/* Third Section */}
        <AllBranchesQuestionBarChart
          startDate={selectedFiltersState.startDate}
          endDate={selectedFiltersState.endDate}
          governorates={selectedFiltersState.governorates}
          facilityTypes={selectedFiltersState.facilityTypes}
          branches={selectedFiltersState.branches}
          divisions={selectedFiltersState.divisions}
        />
        {/* Fourth Section */}
        <AllBranchesComparisonTable
          startDate={selectedFiltersState.startDate}
          endDate={selectedFiltersState.endDate}
          governorates={selectedFiltersState.governorates}
          facilityTypes={selectedFiltersState.facilityTypes}
          branches={selectedFiltersState.branches}
          divisions={selectedFiltersState.divisions}
        />
        {/* </>
        )} */}
      </Box>
    </Box>
  );
};

export const insightSemantics = {
  positive: "positive",
  neutral: "neutral",
  negative: "negative",
};
export const insightArrowDirection = {
  up: "up",
  flat: "flat",
  down: "down",
};

const insightsInfo = {
  CSAT: {
    title: "CSAT Score",
    question: "Rate your overall experience",
    description:
      "The CSAT score is the count of all positive responses (4 or 5), divided by the total number of responses collected, then multiplied by 100. The outcome is basically the percentage of satisfied customers.",
  },
  AS: {
    title: "Satisfaction Average",
    question: "Rate your overall experience",
    description:
      "The Satisfaction Average is the Sum of scores of all responses divided by the total number of responses (given as an average out of 5)",
  },
  NPS: {
    title: "NPS (Net Promoters Score)",
    question: "How likely are you to recommend us?",
    description:
      "To calculate the Net Promoter Score, we subtract the percentage of Detractors (giving a score from below 7) from the percentage of Promoters (giving a score of 9 or 10). Neutral scores (7 or 8) are not included.",
  },
};

export const RevampedLandingPageCard = ({
  label,
  labelHeight = undefined,
  showSeparator = false,
  width,
  height,
  button,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={width}
      height={height ? height : "auto"}
      borderRadius={3}
      boxShadow={2}
      pt={2}
      px={2}
      pb={2}
      bgcolor={primaryColors.base.white}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        height={labelHeight ? labelHeight : "70px"}
        pb={2}
        borderBottom={
          showSeparator ? `1px solid ${primaryColors.gray[200]}` : undefined
        }
        mb={showSeparator ? "20px" : null}
      >
        {label}
        {button}
      </Box>
      {children}
    </Box>
  );
};

const NotEnoughBranchesSelected = () => {
  return (
    <Box
      borderRadius={3}
      boxShadow={2}
      padding={2}
      bgcolor={primaryColors.base.white}
    >
      <Typography
        variant="text-lg"
        fontWeight={fontWeightVariations.medium}
        color={primaryColors.gray[900]}
      >
        Select more than one branch or division to see comparisons and ranking
        of performance
      </Typography>
    </Box>
  );
};
